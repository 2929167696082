import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Form, Field, FormElement, FieldWrapper, } from "@progress/kendo-react-form";

import { FormInput, FormDatePicker, FormTextArea, FormMultiSelect, } from "./../form-components";
import "@progress/kendo-theme-default/dist/all.css";
import { Label } from "@progress/kendo-react-labels";
import { ListView } from "@progress/kendo-react-listview";
import SearchBox from "./SearchBox";
import AddDialog from "./AddDialog";
import { Tooltip, Form as AntdForm, Select, Modal, Row, Col, Table } from 'antd';
import { filterBy } from "@progress/kendo-data-query";
import "./ActionItemGrid.less";

import { getActionItems, saveActionItem } from "../../services/actionItemsService"

import { mockStatus, rails, sampleProducts } from './MockData'
import moment from "moment";

const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const initialFormData = { formType: 'Add', initialData: {} };
const initialDataState = { sort: [{ field: "code", dir: "asc" }], take: 10, skip: 0, };

function PocCell(props) {
  const { dataItem } = props;
  return (
    <td className="k-command-cell">
      <ul className="poc-list">
        {dataItem.poc && dataItem.poc.map((item, index) => (
          <li key={index}>{item.displayName}</li>
        ))}
      </ul>
    </td>
  );
}

const DropdownFilterCell = props => {
  let hasValue = value => Boolean(value && value !== props.defaultItem);
  const onChange = event => {
    hasValue = hasValue(event.target.value);
    console.log('hasValue', hasValue)
    props.onChange({
      value: hasValue ? event.target.value : '',
      operator: hasValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent
    });
  };
  const onClearButtonClick = event => {
    event.preventDefault();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event
    });
  };
  return <div className="k-filtercell">
    <DropDownList data={props.data} onChange={onChange} value={props.value || props.defaultItem} defaultItem={props.defaultItem} />
    <Button title="Clear" disabled={!hasValue(props.value)} onClick={onClearButtonClick} icon="filter-clear" />
  </div>;
};

const StatusFilterCell = (props) => {
  const statusSource = ["Open", "Close", "Hold"];
  return (<DropdownFilterCell
    {...props}
    data={statusSource}
    defaultItem={"Select status"}
  />)
};

export default function ActionItemGrid(props) {

  // const navigate = useNavigate();
  const [form] = AntdForm.useForm();
  const [formData, setFormData] = useState(initialFormData)

  const [dataState, setDataState] = useState(initialDataState);
  const [typeFilter, setTypeFilter] = useState("All");

  const [dialogVisible, setDialogVisible] = useState(false);
  const [viewDialogVisible, setViewDialogVisible] = useState(false);

  // const [dataStatus, setDataStatus] = useState([]);
  // const [formFieldsValue, setFormFieldsValue] = useState({})

  const [cacheDataList, setCacheDataList] = useState([]) // cache data list for filter.
  const [dataList, setDataList] = useState([]); // data list for page render.
  const [filter, setFilter] = React.useState();
  const filterChange = (event) => {
    // console.log('event', event)
    setDataList(filterBy(cacheDataList, event.filter));
    setFilter(event.filter);
  };

  useEffect(() => {
    setCacheDataList(rails.map(e => ({
      ...e,
      etc: new Date(e.etc),// convert string date to Date type
      dateAssigned: new Date(e.dateAssigned),// convert string date to Date type
      closureDate: new Date(e.closureDate),// convert string date to Date type
    })));
    setDataList(rails);

    // getActionItems().then(data => {
    //   //console.log('data?.data', data?.data)
    //   let d = data?.data;
    //   setDataList(data?.data);
    // })
  }, []);

  const [dialogData, setDialogData] = useState({
    rollingActionID: "",
    title: "",
    status: "",
    dateAssigned: "",
    poc: "",
    etc: "",
    closureCriteria: "",
    remarks: "",
    source: "",
    type: ""
  });

  const onTypeFilterClicked = (type) => {
    setTypeFilter(type);
    console.log(" Filter type", type);
  };

  const handleSubmit = (dataItem) => alert(JSON.stringify(dataItem, null, 2));

  const handlerSearch = (value) => {
    console.log("Search click", value);
  };

  const handlerClick = (value) => {
    console.log("click", value);
  };

  const handlerOpenDialog = () => {
    // console.log('handlerOpenDialog')
    setViewDialogVisible(true);
    setFormData(initialFormData)
  };

  const handlerCancelDialog = () => {
    // console.log('handlerCancelDialog')
    setViewDialogVisible(false);
    setFormData(initialFormData)
  }


  // submit form
  const handlerSubmitForm = () => {
    form.validateFields()
      .then(values => {

        let formValues = form.getFieldsValue(true);
        let finalFormValues = {
          ...formValues,
          poc: formValues?.poc?.map(e => e.key)
        }
        //console.log('form.getFieldsValue()', form.getFieldsValue(true))
        // need to call API for save data here ...

        saveActionItem(finalFormValues).then(data => {
          handlerCancelDialog();// reset form
        })

      })
      .catch(errorInfo => {
        console.log('errorInfo', errorInfo)
      });
  }

  // edit
  const handlerEditClick = (params) => {

    console.log('params', params);

    const poc = params?.poc?.map((el, i) => ({// change data
      key: el.pocID,
      title: el.displayName,
      description: el.badgeNumber
    }))
    const correctiveActions = params?.correctiveActions?.map((el, i) => ({// chnage data
      key: el.correctiveActionID,
      title: el.title,
      description: el.title
    }))
    const lessonsLearned = params?.lessonsLearned?.map((el, i) => ({// change data
      key: el.lessonLearnedID,
      title: el.topic,
      description: el.topic
    }))

    const initialData = {
      ...params, poc,
      correctiveActions,
      lessonsLearned,
      etc: null,//moment(params.etc),// convert date type to moment
      dateAssigned: moment(params.dateAssigned),// convert date type to moment
      closureDate: moment(params.closureDate),// convert date type to moment
    }
    setFormData({ initialData, formType: 'Update' })
    setViewDialogVisible(true);
  }

  const handleCloseDialogVisible = () => {
    setDialogVisible(false);
    handlerEditClick(dialogData);
  }


  const FormTags = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      ...others
    } = fieldRenderProps;
    const { value } = others;
    return (
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <span className={`tags ${value === "1" ? "Open" : "Close"}`}>
            {value === "1" ? "Open" : "Close"}
          </span>
        </div>
      </FieldWrapper>
    );
  };

  const itemRender = (props, listId, listLabel) => {
    let item = props.dataItem;
    return (
      <div
        className="row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-2">{item[listId]}</div>
        <div className="col-10">{item[listLabel]}</div>
      </div>
    );
  };

  const FormViewList = (fieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      ...others
    } = fieldRenderProps;
    const { value, listLabel, listId } = others;
    return (
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <ListView
            data={value}
            item={(props) => itemRender(props, listId, listLabel)}
            style={{
              width: "100%",
            }}
          />
        </div>
      </FieldWrapper>
    );
  };
  return (
    <div className="action-item-grid-wrap m-2">
      <div className="row m-4">
        <div className="col-12">
          <div className="action-tool-wrap mb10">
            <div className="btn-list">
              <span>DACL</span>
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => handlerClick("123")}
                themeColor="success"
              >
                Rolling Action Items
              </Button>
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => handlerClick("123")}
                themeColor="success"
              >
                Corrective Actions
              </Button>
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => handlerClick("123")}
                themeColor="success"
              >
                Lessons Learned
              </Button>
            </div>
            <SearchBox onClick={handlerSearch} />
          </div>
        </div>
        <div className="col-12 mb10">
          <Button
            selected={typeFilter === "All"}
            togglable={true}
            onClick={() => {
              onTypeFilterClicked("All");
            }}
          >
            All
          </Button>
          <Button
            className="ml5"
            selected={typeFilter === "Daily"}
            onClick={() => {
              onTypeFilterClicked("Daily");
            }}
            togglable={true}
          >
            Daily
          </Button>
          <Button
            className="ml5"
            selected={typeFilter === "Weekly"}
            onClick={() => {
              onTypeFilterClicked("Weekly");
            }}
            togglable={true}
          >
            Weekly
          </Button>
          <Button
            className="ml5"
            selected={typeFilter === "PMR"}
            onClick={() => {
              onTypeFilterClicked("PMR");
            }}
            togglable={true}
          >
            {" "}
            PMR{" "}
          </Button>
          <Button
            className="ml5"
            selected={typeFilter === "Ops Coord"}
            onClick={() => {
              onTypeFilterClicked("Ops Coord");
            }}
            togglable={true}
          >
            Ops Coord
          </Button>

          <Button
            selected={true}
            className="ml5"
            onClick={() => handlerClick("123")}
            themeColor="success"
          >
            My FAILs
          </Button>
          <Button
            className="ml5"
            onClick={() => handlerClick("123")}
            themeColor="success"
          >
            Open Only
          </Button>
          <Button
            className="ml5"
            onClick={() => handlerClick("123")}
            themeColor="success"
          >
            Reset
          </Button>
          <Button
            className="ml5"
            onClick={() => handlerClick("123")}
            themeColor="success"
          >
            Export to Excel
          </Button>
          <Button
            className="ml5"
            onClick={() => handlerOpenDialog()}
            themeColor="info"
          >
            Add New
          </Button>
        </div>
      </div>
      <div className="row m-4 table-wrap">
        <Grid
          groupable
          pageable={true}
          sortable={true}
          data={process(dataList, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
          // data={dataList}
          filterable={true}
          filter={filter}
          onFilterChange={filterChange}
        >
          <GridColumn
            field="rollingActionID"
            title="Rolling Action ID"
            filterable={false}
            cell={(props) => (
              <td onClick={() => {
                //handlerEditClick(props.dataItem)
                setDialogData(props.dataItem);
                console.log('props.dataItem', props.dataItem)
                setDialogVisible(true);
              }}
              >
                <a>{props.dataItem.rollingActionID}</a>
              </td>
            )}
          />
          <GridColumn field="title" title="Title" filterable={true} />
          {/* <GridColumn
            field="tags"
            title="Tag"
            width={"40px"}
            filterable={false}
            cell={(props) => (<td>
              {props.dataItem.tags && props.dataItem.tags.map((el, index) => {
                return <span key={index} className={`${el === 0 ? 'red' : 'green'} tag`}>{el === 0 ? 'A' : 'L'}</span>
              })}
            </td>)
            }
          /> */}
          <GridColumn field="dateAssigned" title="Date Assigned" filter="date" format="{0:d}" />
          <GridColumn field="poc" title="POC" groupable={false} filterable={false} cell={PocCell} />
          <GridColumn field="status" title="Status" filterable={true} filterCell={StatusFilterCell} />
          <GridColumn field="etc" title="ETC" filter="date" format="{0:d}" />
          <GridColumn field="closureDate" title="Closure Date" filter="date" format="{0:d}" />
          <GridColumn field="remarks" title="Remarks" filterable={true}
            cell={(props) => (
              <td>
                <Tooltip placement="topLeft" title={props.dataItem.remarks}>
                  <div className="more-text">{props.dataItem.remarks}</div>
                </Tooltip>
              </td>
            )}
          />
          <GridColumn field="closureCriteria" title="Closure Criteria" filterable={true}
            cell={(props) => (
              <td>
                <Tooltip placement="topLeft" title={props.dataItem.closureCriteria}>
                  <div className="more-text">{props.dataItem.closureCriteria}</div>
                </Tooltip>
              </td>
            )}
          />
          <GridColumn field="" title="Action" width="80px" filterable={false}
            cell={(props) => (
              <td>
                <Button onClick={() => handlerEditClick(props.dataItem)} type="text"> Edit </Button>
              </td>
            )}
          />
        </Grid>
      </div>
      {viewDialogVisible && (
        <AddDialog
          isModalOpen={viewDialogVisible}
          {...formData}
          //handlerSetFormFieldsValue={handlerSetFormFieldsValue}
          form={form}
          handleOk={handlerSubmitForm}
          handleCancel={handlerCancelDialog} />
      )}
      {dialogVisible && (
        <Modal className='detail-modal' open={dialogVisible} maskClosable={true} onCancel={() => setDialogVisible(false)} title={'Detail'} width={1200} footer={[
          <Button key="submit" type="primary" htmltype="submit" onClick={handleCloseDialogVisible}> Submit </Button>
        ]}>

          <AntdForm >
            <Row>
              <Col span={12}>
                <AntdForm.Item label="ID:">
                  <span className="ant-form-text">{dialogData.rollingActionID}</span>
                </AntdForm.Item>
                <AntdForm.Item label="Status:">
                  <span className="ant-form-text">{dialogData.status}</span>
                </AntdForm.Item>
                <AntdForm.Item label="Title:">
                  <span className="ant-form-text">{dialogData.title}</span>
                </AntdForm.Item>
                <AntdForm.Item label="Poc:">
                  <span className="ant-form-text">{dialogData.poc?.map((el, index) => <span style={{ marginRight: 10 }} key={index}>{el?.displayName}</span>)}</span>
                </AntdForm.Item>
                <AntdForm.Item label="DR #:">
                  <span className="ant-form-text">{dialogData.drNo}</span>
                </AntdForm.Item>

              </Col>
              <Col span={12}>
                <AntdForm.Item label="DateAssigned:">
                  <span className="ant-form-text">{(moment(dialogData.dateAssigned).format('yyyy-MM-DD'))}</span>
                </AntdForm.Item>
                <AntdForm.Item label="ETC:">
                  <span className="ant-form-text">{(moment(dialogData.etc).format('yyyy-MM-DD'))}</span>
                </AntdForm.Item>
                <AntdForm.Item label="Closure Criteria:">
                  <span className="ant-form-text">{dialogData.closureCriteria}</span>
                </AntdForm.Item>
                <AntdForm.Item label="Remarks:">
                  <span className="ant-form-text">{dialogData.remarks}</span>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <AntdForm.Item label="Corrective Actions:">
                  <span className="ant-form-text">
                    <Table dataSource={dialogData.correctiveActions} size={'small'} pagination={{ position: ['none', 'none'] }} columns={[
                      { title: 'ID', dataIndex: 'correctiveActionID', key: 'correctiveActionID', width: 100, },
                      { title: 'Title', dataIndex: 'title', key: 'title', width: 200, },
                    ]} />
                  </span>
                </AntdForm.Item>
              </Col>
              <Col span={12}>
                <AntdForm.Item label="Lesson Learneds:">
                  <span className="ant-form-text">
                    <Table dataSource={dialogData.lessonsLearned} width={100} size={'small'} pagination={{ position: ['none', 'none'] }} columns={[
                      { title: 'ID', dataIndex: 'lessonLearnedID', key: 'lessonLearnedID', width: 100, },
                      { title: 'Topic', dataIndex: 'topic', key: 'topic', width: 200, },
                    ]} />
                  </span>
                </AntdForm.Item>
              </Col>
            </Row>
          </AntdForm>
        </Modal>
      )}
    </div>
  );
}