import React from "react";
import "./loading-screen.css";

function LoadingScreen() {
  return (
    <div className="loading-screen d-flex justify-content-center align-items-center">
      <i className="fas fa-spinner fa-spin-fast fa-4x"></i>
    </div>
  );
}

export default LoadingScreen;
