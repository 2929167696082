import { getToken, renewToken, logout } from "./jwtService";
import { API_HOST_PREFIX } from "./serviceHelpers";
import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

function client(endpoint, { method, body, ...customConfig } = {}) {
  const token = getToken();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  }

  if (!method) {
    method = body ? "POST" : "GET";
  }

  const url = `/api/${endpoint}`;
  var config = {
    url,
    method: method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.data = body;
  }

  return new Promise((resolve, reject) => {
    axios(config).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export default client;