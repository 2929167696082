import moment from 'moment';

export const rails = Array(22)
    .fill(0)
    .map((v, i) => ({
        rollingActionID: i + 1,
        title: i + "SPL tickets due within 10 days",
        drNo: "C110829",
        description: "description",
        type: "0",
        source: "0",
        etc: new Date('8/20/2022'),
        dateAssigned: new Date('8/20/2022'),
        closureDate: new Date('8/20/2022'),
        poc: [
            { "id": 1, "displayName": "J.Lin1", "badgeNum": 1, "email": "" },
            { "id": 2, "displayName": "J.Lin2", "badgeNum": 2, "email": "" },
            { "id": 5, "displayName": "J.Lin5", "badgeNum": 5, "email": "" },
            { "id": 7, "displayName": "J.Lin7", "badgeNum": 7, "email": "" },
        ],
        status: i % 2 === 0 ? 'open' : 'close',
        tags: i % 2 === 0 ? [0, 1] : [1],
        remarkes: i == 0 ? "ETC 13 Jun 2022" : "Using the results of testing with the attenuators installed at each antenna",
        closureCriteria: "task completion,task completion,task completiontask completion,task completion",
        sourceType: 1,
        correctiveActions: [
            { "correctiveActionID": 1, "title": "test1", "notes": "", "description": "" },
            { "correctiveActionID": 2, "title": "test2", "notes": "", "description": "" }
        ],
        lessonsLearned: [
            { "lessonLearnedID": 1, "topic": "test1", "notes": "", "description": "" },
            { "lessonLearnedID": 2, "topic": "test2", "notes": "", "description": "" }
        ],
    }));




export const pocSourceData = Array(22)
    .fill([]).map((_, i) => ({ "id": i + 1, "displayName": "J.Lin" + (i + 1), "badgeNum": 1, "email": "" }))
    .map((el, i) => ({
        key: el.id,
        title: el.displayName,
        description: el.displayName
    }))


export const correctiveActionsSource = [
    { "correctiveActionID": 1, "title": "test1", "notes": "", "description": "" },
    { "correctiveActionID": 2, "title": "test2", "notes": "", "description": "" },
    { "correctiveActionID": 3, "title": "test3", "notes": "", "description": "" },
    { "correctiveActionID": 4, "title": "test4", "notes": "", "description": "" },
].map((el, i) => ({
    key: el.correctiveActionID,
    title: el.title,
    description: el.title
}))

export const lessionsLearnedSource = [
    { "lessonLearnedID": 1, "topic": "test1", "notes": "", "description": "" },
    { "lessonLearnedID": 2, "topic": "test2", "notes": "", "description": "" },
    { "lessonLearnedID": 3, "topic": "test3", "notes": "", "description": "" },
    { "lessonLearnedID": 4, "topic": "test4", "notes": "", "description": "" },
].map((el, i) => ({
    key: el.lessonLearnedID,
    title: el.topic,
    description: el.topic
}))



export const mockOtions = [{ value: 'jack', label: 'Jack', }, { value: 'lucy', label: 'Lucy', }, { value: 'Yiminghe', label: 'yiminghe', },]

export const mockStatus = [{ value: '1', label: 'Open', }, { value: '2', label: 'Closed', }, { value: '3', label: 'Hold' },]



export const sampleProducts = [{
    "ProductID": 1,
    "ProductName": "Chai",
    "SupplierID": 1,
    "CategoryID": 1,
    "QuantityPerUnit": "10 boxes x 20 bags",
    "UnitPrice": 18,
    "UnitsInStock": 39,
    "UnitsOnOrder": 0,
    "ReorderLevel": 10,
    "Discontinued": false,
    "Category": {
        "CategoryID": 1,
        "CategoryName": "Beverages",
        "Description": "Soft drinks, coffees, teas, beers, and ales"
    },
    "FirstOrderedOn": new Date(1996, 8, 20)
}, {
    "ProductID": 2,
    "ProductName": "Chang",
    "SupplierID": 1,
    "CategoryID": 1,
    "QuantityPerUnit": "24 - 12 oz bottles",
    "UnitPrice": 19,
    "UnitsInStock": 17,
    "UnitsOnOrder": 40,
    "ReorderLevel": 25,
    "Discontinued": false,
    "Category": {
        "CategoryID": 1,
        "CategoryName": "Beverages",
        "Description": "Soft drinks, coffees, teas, beers, and ales"
    },
    "FirstOrderedOn": new Date(1996, 7, 12)
}, {
    "ProductID": 3,
    "ProductName": "Aniseed Syrup",
    "SupplierID": 1,
    "CategoryID": 2,
    "QuantityPerUnit": "12 - 550 ml bottles",
    "UnitPrice": 10,
    "UnitsInStock": 13,
    "UnitsOnOrder": 70,
    "ReorderLevel": 25,
    "Discontinued": false,
    "Category": {
        "CategoryID": 2,
        "CategoryName": "Condiments",
        "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
    },
    "FirstOrderedOn": new Date(1996, 8, 26)
}, {
    "ProductID": 4,
    "ProductName": "Chef Anton's Cajun Seasoning",
    "SupplierID": 2,
    "CategoryID": 2,
    "QuantityPerUnit": "48 - 6 oz jars",
    "UnitPrice": 22,
    "UnitsInStock": 53,
    "UnitsOnOrder": 0,
    "ReorderLevel": 0,
    "Discontinued": false,
    "Category": {
        "CategoryID": 2,
        "CategoryName": "Condiments",
        "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
    },
    "FirstOrderedOn": new Date(1996, 9, 19)
}, {
    "ProductID": 5,
    "ProductName": "Chef Anton's Gumbo Mix",
    "SupplierID": 2,
    "CategoryID": 2,
    "QuantityPerUnit": "36 boxes",
    "UnitPrice": 21.35,
    "UnitsInStock": 0,
    "UnitsOnOrder": 0,
    "ReorderLevel": 0,
    "Discontinued": true,
    "Category": {
        "CategoryID": 2,
        "CategoryName": "Condiments",
        "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
    },
    "FirstOrderedOn": new Date(1996, 7, 17)
}, {
    "ProductID": 6,
    "ProductName": "Grandma's Boysenberry Spread",
    "SupplierID": 3,
    "CategoryID": 2,
    "QuantityPerUnit": "12 - 8 oz jars",
    "UnitPrice": 25,
    "UnitsInStock": 120,
    "UnitsOnOrder": 0,
    "ReorderLevel": 25,
    "Discontinued": false,
    "Category": {
        "CategoryID": 2,
        "CategoryName": "Condiments",
        "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
    },
    "FirstOrderedOn": new Date(1996, 9, 19)
}, {
    "ProductID": 7,
    "ProductName": "Uncle Bob's Organic Dried Pears",
    "SupplierID": 3,
    "CategoryID": 7,
    "QuantityPerUnit": "12 - 1 lb pkgs.",
    "UnitPrice": 30,
    "UnitsInStock": 15,
    "UnitsOnOrder": 0,
    "ReorderLevel": 10,
    "Discontinued": false,
    "Category": {
        "CategoryID": 7,
        "CategoryName": "Produce",
        "Description": "Dried fruit and bean curd"
    },
    "FirstOrderedOn": new Date(1996, 7, 22)
}, {
    "ProductID": 8,
    "ProductName": "Northwoods Cranberry Sauce",
    "SupplierID": 3,
    "CategoryID": 2,
    "QuantityPerUnit": "12 - 12 oz jars",
    "UnitPrice": 40,
    "UnitsInStock": 6,
    "UnitsOnOrder": 0,
    "ReorderLevel": 0,
    "Discontinued": false,
    "Category": {
        "CategoryID": 2,
        "CategoryName": "Condiments",
        "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
    },
    "FirstOrderedOn": new Date(1996, 11, 1)
}, {
    "ProductID": 9,
    "ProductName": "Mishi Kobe Niku",
    "SupplierID": 4,
    "CategoryID": 6,
    "QuantityPerUnit": "18 - 500 g pkgs.",
    "UnitPrice": 97,
    "UnitsInStock": 29,
    "UnitsOnOrder": 0,
    "ReorderLevel": 0,
    "Discontinued": true,
    "Category": {
        "CategoryID": 6,
        "CategoryName": "Meat/Poultry",
        "Description": "Prepared meats"
    },
    "FirstOrderedOn": new Date(1997, 1, 21)
}, {
    "ProductID": 10,
    "ProductName": "Ikura",
    "SupplierID": 4,
    "CategoryID": 8,
    "QuantityPerUnit": "12 - 200 ml jars",
    "UnitPrice": 31,
    "UnitsInStock": 31,
    "UnitsOnOrder": 0,
    "ReorderLevel": 0,
    "Discontinued": false,
    "Category": {
        "CategoryID": 8,
        "CategoryName": "Seafood",
        "Description": "Seaweed and fish"
    },
    "FirstOrderedOn": new Date(1996, 8, 5)
}];
