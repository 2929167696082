import React, { useState } from "react";
import { createContext } from "react";

export const AuthContext = createContext({});

function AuthProvider(props) {
  const [user, setUser] = useState({})
  return (
    <AuthContext.Provider value={{ user, setUser }}
      {...props}
    />
  );
}

export { AuthProvider };
