import { Button, Modal, Steps, Form, Input, Select, Transfer, AutoComplete, Row, Col, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MyTransfer from './MyTransfer'
import LessonsLearned from './LessonsLearned';
import CorrectiveAction from './CorrectiveAction';
import Drag from './Drag';
import "./AddDialog.less";

import { getPoc } from "../../services/pocService"

import {
    pocSourceData as pocSourceDataMock,
    correctiveActionsSource as correctiveActionsSourceMock,
    lessionsLearnedSource as lessionsLearnedSourceMock
} from './MockData'


const { Step } = Steps;
const { Option } = Select;
const { TextArea, Search } = Input;

const dateFormat = 'MM/DD/YYYY';

export default function AddDialog(props) {
    const { form, handleOk, isModalOpen, handleCancel, initialData, formType } = props

    const [current, setCurrent] = useState(0);
    const [title, setTitle] = useState('')

    const [pocSourceData, setPocSourceData] = useState([]);
    const [correctiveActionsSource, setCorrectiveActionsSource] = useState([]);
    const [lessionsLearnedSource, setLessionsLearnedSource] = useState([]);



    useEffect(() => {
        if (formType === 'Add') {
            setTitle('Add New')
            form.resetFields();
        } else if (formType === 'Update') {
            setTitle('Update')
            form.setFieldsValue(initialData);
        }


        //Load poc
        // getPoc().then(data => {
        //     //console.log('data?.data', data?.data)

        //     var pocdata = data?.data.map((el, i) => ({
        //         key: el.pocID,
        //         title: el.displayName,
        //         description: el.badgeNumber
        //     }))

        //     setPocSourceData(pocdata);
        //   })

        // initialize source data
        //setPocSourceData(pocSourceDataMock);
        setCorrectiveActionsSource(correctiveActionsSourceMock);
        setLessionsLearnedSource(lessionsLearnedSourceMock);

    }, [])

    const [isAddCorrectiveActionModalOpen, setIsAddCorrectiveActionModalOpen] = useState(false)
    const [correctiveActionAddForm] = Form.useForm();
    // const onAddCorrectiveActionFinish = (values) => console.log('Success:', values) // need to call API for save data to DB

    const [isAddLessonLearnedModalOpen, setIsAddLessonLearnedModalOpen] = useState(false)
    const [lessonLearnedAddForm] = Form.useForm();
    // const onAddLessonLearnedFinish = (values) => console.log('Success:', values) // need to call API for save data to DB

    const changeCurrent = (current) => { // valiadate fields when step change.
        form.validateFields().then(values => { setCurrent(current) })
        // setCurrent(current)
    }

    const handlerEtcDateChange = (date, dateString) => {
        console.log(date, dateString);
    }

    const handlerPocSearch = (value) => {
        console.log("handlerPocSearch", value);
        // Call api  - > source
        // setPocSourceData(source);
    };

    const handlerPocChange = (value) => {
        if (value.length >= 3) {
            console.log("handlerPocChange", value);
            // Call api  - > source
            // setPocSourceData(source);
        }
    };


    const handlerCorrectiveActionsSearch = (value) => {
        // console.log("handlerCorrectiveActionsSearch", value);
        setCorrectiveActionsSource(correctiveActionsSourceMock.slice(1, 4));
    };

    const handlerLessonsLearnedSearch = (value) => {
        // console.log("handlerLessonsLearnedSearch", value);
        setLessionsLearnedSource(lessionsLearnedSourceMock.slice(1, 4));
    };

    return (<>
        <Modal className='add-view-dialog-wrapper' maskClosable={false} title={title} width={1200}

            footer={[
                <Button key="back" disabled={current === 0} onClick={() => { if (current > 0) { changeCurrent(current - 1) } }}> Back </Button>,
                <Button key="next" disabled={current === 4} onClick={() => { if (current < 4) { changeCurrent(current + 1) } }}> Next </Button>,
                // <Button key="submit" disabled={current !== 4} type="primary" htmlType="submit" onClick={handleOk}> Save </Button>,
                <Button key="submit" type="primary" htmlType="submit" onClick={handleOk}> Submit </Button>,
            ]}
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Steps type="navigation" size="small" current={current} onChange={value => changeCurrent(value)} className="site-navigation-steps" >
                <Step title="Step 1" description="Basic Information" />
                <Step title="Step 2" description="Personnel" />
                <Step title="Step 3" description="Corrective Actions" />
                <Step title="Step 4" description="Lessons Learned" />
                <Step title="Step 5" description="Review and Confirm" />
            </Steps>
            <div className='step-contents-wrapper' >
                <Form name="control-ref" form={form}
                    // onValuesChange={onValuesChange}
                    // onFinish={onFinish}
                    // rules={[{ validator: checkPrice, },]}
                    labelCol={{ span: 6, }} wrapperCol={{ span: 18, }}
                >
                    {current === 0 && (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item name="title" label="Title" rules={[{ required: true, },]} ><Input /></Form.Item>
                                    <Form.Item name="type" label="Type" rules={[{ required: true, },]} >
                                        <Select placeholder="Select Type" onChange={value => console.log('value', value)} allowClear >
                                            <Option value="501">Daily</Option>
                                            <Option value="502">Weekly</Option>
                                            <Option value="503">Phase Out</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="source" label="Source" rules={[{ required: true, },]} >
                                        <Select placeholder="Select Source" onChange={value => console.log('value', value)} allowClear >
                                            <Option value="600">None</Option>
                                            <Option value="601">PMR</Option>
                                            <Option value="602">Ops Coord</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="closureCriteria" label="Closure Criteria" rules={[{ required: false, },]} >
                                        <TextArea rows={4} />
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <Form.Item name="status" label="Status" rules={[{ required: true, },]} >
                                        <Select placeholder="Select status" onChange={value => console.log('value', value)} allowClear >
                                            <Option value="1">Open</Option>
                                            <Option value="2">Closed</Option>
                                            <Option value="3">On Hold</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="etc" label="ETC" rules={[{ required: true, },]} >
                                        <DatePicker onChange={handlerEtcDateChange} format={dateFormat} style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item name="remarks" label="Remarks" rules={[{ required: false, },]} >
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    {current === 1 && (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Search:" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} >
                                        <Search size="large" placeholder="please input" onChange={(e) => handlerPocChange(e.target.value)} onSearch={handlerPocSearch} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="">
                                    <Form.Item label="Role:"
                                        labelCol={{ span: 4, }} wrapperCol={{ span: 16, }}
                                    >
                                        <Select size="large" placeholder="Select Role" onChange={value => console.log('value', value)} allowClear >
                                            <Option value="302">Point of Contact</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="poc" label="Candidates:" rules={[{ required: false }]}
                                        labelCol={{ span: 4, }} wrapperCol={{ span: 20, }}>
                                        {/* <MyTransfer
                                            dataSource={pocSourceData}
                                            titles={['Candidates List Box', 'POC List Box']}
                                            listStyle={{ width: 384, height: 400, }} /> */}

                                        <Drag />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    {current === 2 &&
                        <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Search:" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} >
                                        <Search size="large" placeholder="please input" onSearch={handlerCorrectiveActionsSearch} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col span={6} offset={2}>
                                    <Button size='large' onClick={() => setIsAddCorrectiveActionModalOpen(true)}>Add Corrective Action</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="correctiveActions" label="Candidates:" rules={[{ required: false }]}
                                        labelCol={{ span: 4, }} wrapperCol={{ span: 20, }}>
                                        <MyTransfer
                                            dataSource={correctiveActionsSource}
                                            titles={['Candidates List Box', 'Corrective Actions List Box']}
                                            listStyle={{ width: 384, height: 400, }} />

                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                    {current === 3 &&
                        <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Search:" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} >
                                        <Search size="large" placeholder="please input" onSearch={handlerLessonsLearnedSearch} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col span={6} offset={2}>
                                    <Button size="large" onClick={() => setIsAddLessonLearnedModalOpen(true)}>Add Lessons Learned</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="lessonsLearned" label="Candidates:" rules={[{ required: false }]}
                                        labelCol={{ span: 4, }} wrapperCol={{ span: 20, }}>
                                        <MyTransfer
                                            dataSource={lessionsLearnedSource}
                                            titles={['Candidates List Box', 'Lesson Learned List Box']}
                                            listStyle={{ width: 384, height: 400, }} />

                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                    {current === 4 &&
                        <>
                            <Form.Item label="Title" >
                                <div className="">{form.getFieldValue('title')}</div>
                            </Form.Item>
                            <Form.Item label="Description" >
                                <div className="">{form.getFieldValue('description')}</div>
                            </Form.Item>
                            <Form.Item label="Status" >
                                <div className="">{form.getFieldValue('status') === 1 ? 'Open' : 'Close'}</div>
                            </Form.Item>
                            <Form.Item label="Type" >
                                <div className="">{form.getFieldValue('type') === 1 ? 'Daily' : 'Weekly'}</div>
                            </Form.Item>
                            <Form.Item label="Source" >
                                <div className="">{form.getFieldValue('source') === 1 ? 'None' : 'Close'}</div>
                            </Form.Item>
                            <Form.Item label="Remarkes" >
                                <div className="">{form.getFieldValue('remarkes')}</div>
                            </Form.Item>
                            <Form.Item label="Assignees" >
                                {form.getFieldValue('poc')?.map((el, index) => <span style={{ marginRight: 10 }} key={index}>{el?.title}</span>)}
                            </Form.Item>
                            <Form.Item label="Corrective Actions" >
                                {form.getFieldValue('correctiveActions')?.map((el, index) => <span style={{ marginRight: 10 }} key={index}>{el?.title}</span>)}
                            </Form.Item>
                            <Form.Item label="Lessons Learned" >
                                {form.getFieldValue('lessonsLearned')?.map((el, index) => <span style={{ marginRight: 10 }} key={index}>{el?.title}</span>)}
                            </Form.Item>
                        </>
                    }
                </Form>

            </div>
        </Modal>

        <CorrectiveAction
            isAddCorrectiveActionModalOpen={isAddCorrectiveActionModalOpen}
            correctiveActionAddForm={correctiveActionAddForm}
            setIsAddCorrectiveActionModalOpen={setIsAddCorrectiveActionModalOpen}
        // onAddCorrectiveActionFinish={onAddCorrectiveActionFinish}
        />
        <LessonsLearned
            isAddLessonLearnedModalOpen={isAddLessonLearnedModalOpen}
            lessonLearnedAddForm={lessonLearnedAddForm}
            setIsAddLessonLearnedModalOpen={setIsAddLessonLearnedModalOpen}
        // onAddLessonLearnedFinish={onAddLessonLearnedFinish}
        />
    </>
    );
}