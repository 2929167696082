import client from "./apiService";

// Auth
const localStorageKey = "__token__";

function handleAuthenticationResponse({ token, user }) {
  setToken(token);
  return user;
}

function getSelfProfile() {
  const token = getToken();

  if (!token) {
    return Promise.resolve(null);
  }

  return client("ldap/self").catch((error) => {
    logout();
    return Promise.reject(error);
  });
}

function login({ username, password }) {
  return new Promise((resolve, reject) => {
    resolve({ code: 200, msg: "successful", data: { user: `${username}`, token: "token01" } });
  });

  // return client("login", { body: { username, password } })
  //   .then(handleAuthenticationResponse)
  //   .catch(function (response) {
  //     return response.json().then((e) => Promise.reject(e));
  //   });
}

function renewToken() {
  return client("token/renew", { method: "POST" }).then(({ token }) => {
    setToken(token);
    return token;
  });
}

function register({ username, password }) {
  return client("membership/request", { body: { username, password } })
    .then(function (response) {
      return response.json().then((e) => Promise.return(e));
    })
    .catch(function (response) {
      return response.json().then((e) => Promise.reject(e));
    });
}

function logout() {
  window.localStorage.removeItem(localStorageKey);
  window.location = "/";
  // return Promise.resolve();
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

function setToken(token) {
  window.localStorage.setItem(localStorageKey, token);
}

function getInactivityTimeoutDuration() {
  return client("membership/inactivity_timeout");
}

export {
  login,
  renewToken,
  register,
  logout,
  getToken,
  getSelfProfile,
  getInactivityTimeoutDuration,
};

//LDAP

function getUserProfile(primaryUserKey) {
  return client(`ldap/users/${primaryUserKey}`);
}

function searchUserProfiles(searchTerm) {
  return client(`ldap/search?searchTerm=${searchTerm}`);
}

export { getUserProfile, searchUserProfiles };

//User

function updateSelfLastActive() {
  return client(`membership/self/active`, { method: "PUT" });
}

function getUsers(page, per_page) {
  return client(`membership/users?page=${page}&per_page=${per_page}`);
}

function getUserAccount(primaryUserKey) {
  return client(`membership/users/${primaryUserKey}`);
}

function getUserRoles(primaryUserKey) {
  return client(`membership/users/${primaryUserKey}/roles`);
}

function setUserRoles(primaryUserKey, roles) {
  return client(`membership/users/${primaryUserKey}/roles`, { body: roles });
}

function lockoutUser(primaryUserKey) {
  return client(`membership/users/${primaryUserKey}/lockout`, {
    method: "PUT",
  });
}

function unlockUser(primaryUserKey) {
  return client(`membership/users/${primaryUserKey}/unlock`, { method: "PUT" });
}

function approveUser(primaryUserKey) {
  return client(`membership/users/${primaryUserKey}/approve`, {
    method: "PUT",
  });
}

function denyUser(primaryUserKey) {
  return client(`membership/users/${primaryUserKey}/deny`, { method: "PUT" });
}

function getRoles() {
  return client(`roles`);
}

function getRole(id) {
  return client(`roles/${id}`);
}

export {
  updateSelfLastActive,
  getUsers,
  getUserAccount,
  getUserRoles,
  setUserRoles,
  lockoutUser,
  unlockUser,
  approveUser,
  denyUser,
  getRoles,
  getRole,
};
