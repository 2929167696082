import React from "react";
import ActionItemGrid from "../../components/ActionItems/ActionItemGrid";
import SiteNav from "../Navbar";

const ActionItemHome = (props) => {
  return (
    <div>
      <SiteNav />
      <ActionItemGrid
        actionItems={props.actionItems}
        setActionItems={props.setActionItems}
        gridState={props.gridState}
        setGridState={props.setGridState}
      />
    </div>
  );
};

export default ActionItemHome;
