import React from "react";
import AppProviders from "./contexts";
import LoadingScreen from "./components/LoadingScreen";

import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from './utils/PrivateRoutes'
import LoginPage from "./pages/login";
import ActionItemHome from "./components/ActionItems/ActionItemHome";
import LessonsLearnedGrid from './components/LessonsLearned/LessonsLearnedGrid'
import CorrectiveActionsGrid from './components/CorrectiveActions/CorrectiveActionsGrid'
import Home from "./pages/home";

import 'antd/dist/antd.css';

const App = () => {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <AppProviders>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path={"/home"} element={<Home />} />
            <Route path={"/actionitems"} element={<ActionItemHome />} />
            <Route path={"/correctiveactions"} element={<CorrectiveActionsGrid />} />
            <Route path={"/lessonslearned"} element={<LessonsLearnedGrid />} />
            {/* default route  */}
            <Route path={"*"} element={<Navigate to="/login" replace={true} />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </AppProviders>
    </React.Suspense>
  );
};

export default App;
