import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom'
import "./index.css";
import { login, register } from "../../services/jwtService";
import BackgroundImg from "../../assets/images/login_background.jpg";
import { AuthContext } from "../../contexts/authContext";

function LoginForm({ onSubmit, title, buttonText }) {
  const [isPending, setIsPending] = React.useState(false);
  const [error, setError] = React.useState(null);

  const context = useContext(AuthContext);
  const navigate = useNavigate();

  function handleSubmit(event) {
    event.preventDefault();
    const { username, password } = event.target.elements;

    setIsPending(true);
    onSubmit({ username: username.value, password: password.value }).then((response) => {

      const { code, msg, data } = response
      if (code === 200) {
        localStorage.setItem("user", JSON.stringify(data));
        navigate("/actionitems");
        // window.location.reload();
      }
      setIsPending(false);
    }).catch((e) => {
      username.value = "";
      password.value = "";
      setError(e);
      setIsPending(false);
      return Promise.resolve(null);
    });
  }

  return (
    <form className="form-signin" onSubmit={handleSubmit}>
      <h1 className="h3 mb-3 font-weight-normal">{title}</h1>
      <label htmlFor="username" className="sr-only">
        JPL Username
      </label>
      <input
        type="text"
        id="username"
        className="form-control"
        placeholder="JPL Username"
        required
        autoFocus
      />
      <label htmlFor="password" className="sr-only">
        JPL Password
      </label>
      <input
        type="password"
        id="password"
        className="form-control"
        placeholder="JPL Password"
        autoComplete="false"
        required
      />
      <button
        className="btn btn-lg btn-primary btn-block"
        type="submit"
        disabled={isPending}
      >
        {buttonText}{" "}
        {isPending ? (
          <i className="fas fa-spinner fa-spin fa-spin-fast ml-5"></i>
        ) : null}
      </button>
      {error ? (
        <div className="mt-2" style={{ color: "#F00" }}>
          {error ? error.Message : null}
        </div>
      ) : null}
    </form>
  );
}

function LoginPage() {
  const [requestAccess, setRequestAccess] = React.useState(false);

  return (
    <>
      <div className="h-100 d-flex align-items-center">
        <div
          className="card text-white mx-auto overflow-hidden"
          style={{ width: "60%", height: "60%" }}
        >
          <img src={BackgroundImg} className="card-img" alt="..." />
          <div className="card-img-overlay d-flex align-items-center">
            <div className="ml-5 mr-auto my-auto" style={{ width: "30%" }}>
              {!requestAccess && (
                <>
                  <LoginForm
                    onSubmit={login}
                    title="RAIL Login"
                    buttonText="Login"
                  />

                  <div className="mt-4">
                    <span>
                      <button
                        className="text-white btn btn-link"
                        onClick={() => {
                          setRequestAccess(true);
                        }}
                      >
                        Need to Request Access?
                      </button>
                    </span>
                  </div>
                </>
              )}

              {requestAccess && (
                <>
                  <LoginForm
                    onSubmit={register}
                    title="Request Access to RAIL"
                    buttonText="Request"
                  />

                  <div className="mt-4">
                    <span>
                      <button
                        className="text-white btn btn-link"
                        onClick={() => {
                          setRequestAccess(false);
                        }}
                      >
                        Ready to login?
                      </button>
                    </span>
                  </div>
                </>
              )}

              <div className="mt-4">
                <span>
                  <a
                    className="text-white btn btn-link"
                    href="https://dir.jpl.nasa.gov/ui/"
                  >
                    Forgot your password?
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
