import React, { useState, useEffect } from 'react'
import { Button, Modal, Steps, Form, Input, Select, Transfer, AutoComplete, Row, Col, DatePicker } from 'antd';
import MyTransfer from './MyTransfer';
import "./CorrectiveAction.less";

import {
    pocSourceData as pocSourceDataMock,
    correctiveActionsSource as correctiveActionsSourceMock,
    lessionsLearnedSource as lessionsLearnedSourceMock,
    mockOtions
} from './MockData'

const { Step } = Steps;
const { Option } = Select;
const { TextArea, Search } = Input;

export default function CorrectiveAction(props) {
    const { isAddCorrectiveActionModalOpen, correctiveActionAddForm,
        setIsAddCorrectiveActionModalOpen, onAddCorrectiveActionFinish } = props;

    const [current, setCurrent] = useState(0);
    const [pocSourceData, setPocSourceData] = useState([]);
    const [options, setOptions] = useState([])

    useEffect(() => {
        // initial data
        setPocSourceData(pocSourceDataMock);
        setOptions(mockOtions);
    }, [])

    // submit form
    const handleOk = () => {
        correctiveActionAddForm.validateFields()
            .then(values => {
                console.log('form.getFieldsValue()', correctiveActionAddForm.getFieldsValue(true))
                // need to call API for save data here ...
                handlerCancelDialog();// reset form
            })
            .catch(errorInfo => {
                // console.log('errorInfo', errorInfo)
            });
    }

    // cancel
    const handlerCancelDialog = () => {
        setIsAddCorrectiveActionModalOpen(false);
        setCurrent(0);
        correctiveActionAddForm.resetFields();
    }

    // search
    const handlerSearch = () => {
        console.log("@")
        setPocSourceData(pocSourceDataMock.splice(1, 4));
    }

    // change step
    const changeCurrent = (current) => { // valiadate fields when step change.
        correctiveActionAddForm.validateFields().then(values => { setCurrent(current) })
        // setCurrent(current)
    }

    return (
        <Modal title="Add a New Corrective Action" open={isAddCorrectiveActionModalOpen} maskClosable={false}
            onOk={() => correctiveActionAddForm.submit()} width={970} /*style={{ paddingTop: 20 }}*/
            onCancel={handlerCancelDialog}
            footer={[
                <Button key="back" disabled={current === 0} onClick={() => { if (current > 0) { changeCurrent(current - 1) } }}> Back </Button>,
                <Button key="next" disabled={current === 2} onClick={() => { if (current < 4) { changeCurrent(current + 1) } }}> Next </Button>,
                <Button key="submit" disabled={current !== 2} type="primary" htmlType="submit" onClick={handleOk}> Submit </Button>,
            ]}
        >

            <Steps type="navigation" size="small" current={current} onChange={value => changeCurrent(value)} className="site-navigation-steps" >
                <Step title="Step 1" description="Basic Infomation" />
                <Step title="Step 2" description="Personnel" />
                <Step title="Step 3" description="Review and Confirm" />
            </Steps>

            <div className='step-contents-wrapper' style={{ paddingTop: "40px" }} >
                <Form form={correctiveActionAddForm} onFinish={onAddCorrectiveActionFinish}
                    labelCol={{ span: 3, }} wrapperCol={{ span: 9, }}>

                    {current === 0 && (
                        <>
                            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input your title!', },]}
                                labelCol={{ span: 3, }} wrapperCol={{ span: 15, }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Remarks" name="remarks" rules={[{ required: true, message: 'Please input your notes!', },]}
                                labelCol={{ span: 3, }} wrapperCol={{ span: 15, }}
                            >
                                <TextArea />
                            </Form.Item>
                            <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input your  description!', },]}
                                labelCol={{ span: 3, }} wrapperCol={{ span: 15, }}
                            >
                                <TextArea />
                            </Form.Item>

                        </>
                    )}
                    {current === 1 && (
                        <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Search:" labelCol={{ span: 8, }} wrapperCol={{ span: 16, }} >
                                        <Search size="large" placeholder="please input" onSearch={handlerSearch} enterButton />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="">
                                    <Form.Item label="Role:"
                                        labelCol={{ span: 4, }} wrapperCol={{ span: 16, }}
                                    >
                                        <Select options={options} size="large" placeholder="Select status" onChange={value => console.log('value', value)} allowClear >
                                            {/* <Option value="1">POC1</Option>
                                            <Option value="0">POC2</Option> */}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="poc" label="Candidates:" rules={[{ required: true }]}
                                        labelCol={{ span: 4, }} wrapperCol={{ span: 20, }}>
                                        <MyTransfer
                                            dataSource={pocSourceData}
                                            titles={['Candidates List Box', 'POC List Box']}
                                            listStyle={{ width: 308, height: 400, }} />

                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                    {current === 2 && (
                        <>
                            <Form.Item label="Title" >
                                <div className="">{correctiveActionAddForm.getFieldValue('title')}</div>
                            </Form.Item>
                            <Form.Item label="Description" >
                                <div className="">{correctiveActionAddForm.getFieldValue('description')}</div>
                            </Form.Item>
                            <Form.Item label="Remarkes" >
                                <div className="">{correctiveActionAddForm.getFieldValue('remarks')}</div>
                            </Form.Item>
                            <Form.Item label="Assignees:" >
                                {correctiveActionAddForm.getFieldValue('poc')?.map((el, index) => <span style={{ marginRight: 10 }} key={index}>{el?.title}</span>)}
                            </Form.Item>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    )
}
