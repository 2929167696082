import client from "./apiService";
import { API_HOST_PREFIX } from "./serviceHelpers";

const getActionItems = () => {
  return client("/rolling/all", { method: "GET" })
    .catch(function (response) {
      console.log('error');
      return response.json().then((e) => Promise.reject(e));
    });
};

const saveActionItem = (parms) => {
  return client("/rolling", { method: "POST", body: parms })
    .catch(function (response) {
      console.log('error');
      return response.json().then((e) => Promise.reject(e));
    });
};

// const updateActionItem = () => {
//   return client("/rolling/all", { method: "GET" });
//     // .catch(function (response) {
//     //   console.log('error');
//     //   return response.json().then((e) => Promise.reject(e));
//     // });
// };

export { getActionItems, saveActionItem }
