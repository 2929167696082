import React, { useState, useEffect } from 'react';
import { Transfer } from 'antd';

export default function MyTransfer({ value = [], onChange, dataSource, listStyle, titles, showSearch = false }) {
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setTargetKeys(value.map((el, i) => el.key));
    }, [])

    const handlerChange = (nextTargetKeys, direction, moveKeys) => {
        // console.log('targetKeys:', nextTargetKeys);
        // console.log('direction:', direction);
        // console.log('moveKeys:', moveKeys);
        setTargetKeys(nextTargetKeys);
        onChange?.(dataSource.filter((el) => nextTargetKeys.includes(el.key)))
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        // console.log('sourceSelectedKeys:', sourceSelectedKeys);
        // console.log('targetSelectedKeys:', targetSelectedKeys);
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    // const onScroll = (direction, e) => {
    //     console.log('direction:', direction);
    //     console.log('target:', e.target);
    // };

    const handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    }

    const filterOption = (inputValue, option) => option.description.indexOf(inputValue) > -1;


    return (
        <Transfer
            dataSource={dataSource}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            showSearch={showSearch}
            onSearch={handleSearch}
            filterOption={filterOption}
            onChange={handlerChange}
            onSelectChange={onSelectChange}
            listStyle={listStyle}
            titles={titles}
            // onScroll={onScroll}
            render={(item) => item.title}
        />
    );
};