import React from "react";
import SiteNav from '../Navbar'
const CorrectiveActionsGrid = () => {
  return <div>
    <SiteNav />
    CorrectiveActionsGrid
  </div>;
};

export default CorrectiveActionsGrid;
