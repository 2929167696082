
import React, { useState, useEffect } from 'react';
import { Button, Modal, Steps, Form, Input, Select, Transfer, AutoComplete, Row, Col, DatePicker } from 'antd';



const list = [
    {
        uid: '1',
        text: '序列1'
    },
    {
        uid: '2',
        text: '序列2'
    },
    {
        uid: '3',
        text: '序列3'
    },
    {
        uid: '4',
        text: '序列4'
    },
    {
        uid: '5',
        text: '序列5'
    },
]

const Drag = () => {
    const [rightList, setRightList] = useState(list)
    const [leftList, setLeftList] = useState(list)

    //鼠标华划过接受拖拽元素的事件
    const handleDrop = (callBack, e, arrow) => {
        const { dataset: { id } } = e.target
        const curData = JSON.parse(e.dataTransfer.getData('itemData'))
        callBack(prevData => {
            const diffData = prevData.filter(item => item.uid !== curData.uid)
            // id 不存在是在不同盒子内拖拽  存在则是在本身盒子内拖拽
            if (!id) return [...diffData, curData]
            // 找到鼠标划过的目标元素的其盒子内的位置
            const index = diffData.findIndex(item => item.uid === id)
            //把拖拽元素放置在鼠标划过元素的上方
            diffData.splice(index, 0, curData)
            return diffData

        })
        //朝左拖拽
        if (arrow === 'left') {
            setRightList(prvData => prvData.filter(item => item.uid !== curData.uid))
        }
        // 朝右拖拽
        else {
            setLeftList(prvData => prvData.filter(item => item.uid !== curData.uid))
        }
    }
    // 拖拽元素进入目标元素时触发事件-为目标元素添加拖拽元素进入时的样式效果
    const handleDragEnter = e => e.target.classList.add('over')

    // 拖拽元素离开目标元素时触发事件-移除目标元素的样式效果
    const handleDragLeave = e => e.target.classList.remove('over')

    return (

        <Row>
            <Col span={8}>
                <div className='left'
                    onDragOver={(e) => { e.preventDefault() }}
                    onDrop={(e) => handleDrop(setLeftList, e, 'left')}
                >
                    {
                        leftList.map(item => (
                            <div className='item'
                                draggable
                                key={item.uid}
                                data-id={item.uid}
                                onDragStart={(e) => { e.dataTransfer.setData('itemData', JSON.stringify(item)) }}
                            >{item.text}</div>
                        ))
                    }
                </div>
            </Col>
            <Col span={8}>
                <div className='right'
                    onDragOver={(e) => { e.preventDefault() }}
                    onDrop={(e) => handleDrop(setRightList, e, 'right')}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                >
                    {
                        rightList.map(item => (
                            <div className='item'
                                draggable
                                key={item.uid}
                                data-id={item.uid}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData('itemData', JSON.stringify(item))
                                }}
                            >{item.text}</div>
                        ))
                    }
                </div>
            </Col>


        </Row>
    )
}

export default Drag

