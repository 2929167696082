import React, { useState, useEffect } from "react";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { Icon } from "@progress/kendo-react-common";
import { Input } from "@progress/kendo-react-inputs";

export default function SearchBox({
  onClick = () => {},
  id = "searchItem",
  style = { width: "30px", height: "30px" },
  className = "ml5",
}) {
  const [value, setValue] = useState("");
  return (
    <>
      <FloatingLabel className={className} editorId={id} editorValue={value}>
        <Input id={id} value={value} onChange={(e) => setValue(e.value)} />
      </FloatingLabel>
      <div className="icon-wrap">
        <Icon name="search" onClick={() => onClick(value)} style={style} />
      </div>
    </>
  );
}
