import React from "react";
import { Link } from "react-router-dom";

function SiteNav(props) {

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-white border-bottom shadow-sm">
      <div className="collapse navbar-collapse" id="navbar">
        <ul className="navbar-nav">
          <li className={`nav-item `}>
            <Link className="nav-link" to="/home">
              Home
            </Link>
          </li>
          <li className={`nav-item `}>
            <Link className="nav-link" to="/actionitems">
              Action Items
            </Link>
          </li>

          <li className={`nav-item `}>
            <Link className="nav-link" to="/correctiveactions">
              Corrective Actions
            </Link>
          </li>

          <li className={`nav-item `}>
            <Link className="nav-link" to="/lessonslearned">
              Lessons Learned
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default SiteNav;
